export const en = {
  button: 'Button',
  ads: 'ADVERTISMENT',
  techClosed: 'Closed for maintenance',
  soon: 'Coming soon',
  noSeats: 'No seats available',
  events: 'Events and&nbsp;activities',
  showAll: 'Show all',
  reset: 'Reset',
  filterEmptyResult:
    'Select different options and we will find suitable entertainment for you!',
  showMore: 'Show more',
  fieldRequired: 'This field is required',
  fieldPasswordError:
    'Password must consist of numbers and Latin letters and be at least 8 characters long',
  fieldEmailError: 'Invalid E-mail format',
  fieldPhoneError: 'Invalid phone number format',
  fieldBirthdayError:
    'The date of birth cannot be later than today and earlier than 1900',
  fieldFormatError: 'Invalid field format',
  fieldEmailDuplicate: 'A user with this Email already exists',
  fieldEmailNotFound: 'User with this Email not found',
  fieldPasswordMismatch: 'Passwords do not match',
  fieldError: 'Field input error',
  map: 'Map',
  objectParams: 'Object parameters',
  home: 'Home',
  placement: 'Types of accommodation',
  qrShowCard: 'Show card',
  qrSubtitle:
    'Show the QR code at the entrance to the Amusement Park to earn or spend bonuses',
  qrCard: 'Discount card',
  qrError: 'Card not scanning?',
  qrAlert:
    'If the card is not readable, name your<br />unique code from the numbers below:',
  searchAllResults: 'All search results',
  ourSocials: 'Follow us on social media',
  workPeriod: 'Working hours',
  ourAddress: 'Our address',
  phone: 'Phone',
  successRegTitle: 'You successfully<br>registered!',
  successRegDesc: 'We will be waiting for you in "Dream Island"',
  at: 'at',
  from: 'from',
  to: 'to',
  successRegHK:
    'To visit the Hello Kitty Beauty Salon,<br>each guest must purchase a ticket<br>to the amusement park',
  toMain: 'Back to main page',
};
