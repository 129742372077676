import { parseText } from '@/utils/parseText';
import { ArrowLinkIcon } from '../ArrowLinkIcon';
import styles from './FooterLinks.module.scss';
import Link from 'next/link';

type FooterLinkProps = {
  href: string;
  text: string;
};

export function FooterLink({ text, href }: FooterLinkProps) {
  return (
    <Link className={styles.link} key={text} href={href}>
      <span className={styles.title}>{parseText(text)}</span>
      <ArrowLinkIcon staticArrow />
    </Link>
  );
}
