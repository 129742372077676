export const parseText = (text: string | undefined) => {
  if (!text) return '';
  return text
    .replace(/&nbsp;/g, '\u00A0')
    .replace(/&mdash;/g, '\u2014')
    .replace(/&laquo;/g, '\u00AB')
    .replace(/&raquo;/g, '\u00BB')
    .replace(/&quot;/g, '"')
    .replace(/<br>/g, '\n');
};
