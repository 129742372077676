import cn from 'classnames';
import styles from './NavDropdown.module.scss';

import { MenuItem } from '@/types';
import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BACKEND_URL } from '@/constants';
import { pixelRunScript } from '@/utils/pixelRunScript';

type NavDropdownProps = {
  text: string;
  className?: string;
  items: MenuItem[];
  icon: string;
  link: string;
};

export function NavDropdown({
  text,
  className,
  items,
  icon,
  link,
}: NavDropdownProps) {
  const menuClassName = cn(styles.menu, className);
  const [showList, setShowList] = useState(false);
  const ContentRef = useRef<HTMLDivElement>(null);
  const [currentMaxHeight, setCurrentMaxHeight] = useState(0);
  const listClassName = cn(styles.column, {
    [styles.hidden]: !showList,
  });
  const router = useRouter();

  const showContent = () => {
    setShowList(true);
    const content = ContentRef.current;
    if (content) {
      setCurrentMaxHeight(content.scrollHeight + 30);
    }
  };

  const hideContent = () => {
    setShowList(false);
    const content = ContentRef.current;
    if (content) {
      setCurrentMaxHeight(0);
    }
  };

  const handleLinkClick = () => {
    if (text === 'Полезное') pixelRunScript('view_section_help');
  };

  return (
    <div className={styles.wrapper} onMouseLeave={hideContent}>
      <div
        className={menuClassName}
        onMouseOver={showContent}
        onClick={() => {
          if (!link) return;
          router.push(link);
        }}
      >
        <span>{text}</span>
        {icon && (
          <img
            src={`${BACKEND_URL}${icon}`}
            height={20}
            width={20}
            alt="nav-menu"
          />
        )}
        {items.length > 0 && (
          <img src="/svg/down.svg" width={16} height={16} alt="open-menu" />
        )}
      </div>
      {items.length > 0 && (
        <div
          className={listClassName}
          ref={ContentRef}
          style={{ maxHeight: currentMaxHeight }}
        >
          {items.map((item) => (
            <Link
              key={item.id}
              href={item.value}
              className={styles.item}
              onClick={() => handleLinkClick()}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
