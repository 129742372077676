import { useSelector } from 'react-redux';
import styles from './Sidebar.module.scss';
import { selectShowSidebar } from '@/store';
import cn from 'classnames';
import { TABLET_RESOLUTION, darkHeaderPages } from '@/constants';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { MenuItem } from '@/types';
import { Accordion } from '@/components/Accordion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

type SidebarProps = {
  menu: MenuItem[];
};

export function Sidebar({ menu }: SidebarProps) {
  const showSidebar = useSelector(selectShowSidebar);
  const width = useWindowWidth();
  const isMobile = width && width < TABLET_RESOLUTION;
  const router = useRouter();

  const isDetailedPage =
    Object.keys(router.query).length ||
    darkHeaderPages.includes(router.pathname);

  const sidebarClassName = cn(styles.sidebar, {
    [styles.fullView]: isDetailedPage,
  });

  useEffect(() => {
    const body = document.querySelector('body');
    if (showSidebar && body) {
      body.classList.add('modal-opened');
    }
    if (!showSidebar && body) {
      body.classList.remove('modal-opened');
    }
  }, [showSidebar]);

  if (!showSidebar || !isMobile) return null;

  return (
    <aside className={sidebarClassName}>
      {menu.map((item) => (
        <Accordion
          title={item.name}
          key={item.id}
          items={item.items}
          icon={item.icon}
          link={item.value}
        />
      ))}
    </aside>
  );
}
